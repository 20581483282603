@import url(https://fonts.googleapis.com/css2?family=Comfortaa:wght@300&display=swap);
body {
  margin: 0;
  background-color: #03174c;
}
html {
  font-size: 62.5%;
}
a {
  text-decoration: none;
  color: #fff;
}


#switch {
  margin-left: 1% !important;
  display: inline;
  width: 34px;
  height: 14px;
}

#container-fluid {
  padding: 3% 15% !important;
}

#button {
  padding: 3% 15% !important;
  margin-top: 15% !important;
}

#girl {
  width: 243px !important;
  height: 268px !important;
  left: 355px !important;
  top: 100px !important;
}

@media screen and (max-width: 600px) {
}
@media (max-width: 998px) {
  html {
    font-size: 55%;
  }
}
@media (max-width: 768px) {
  html {
    font-size: 45%;
  }
}
.privacy-body {
  padding: 5% 10%;
}
.privacy-body * {
  color: #fff;
}
.privacy-body h1 {
  font-size: 20px;
}
.privacy-body span {
  font-size: 14px;
}
.privacy-body .title {
  font-size: 44px;
  margin-bottom: 24px;
}

.privacy-body .subtitle {
  font-size: 24px;
  margin: 12px 0;
}

@media screen and (max-width: 512px) {
  .privacy-body .title {
    font-size: 32px;
  }
}
html {
  font-size: 62.5%;
}
#logo {
  width: 140px;
  /* height: 60.92px; */
  margin: 8px;
  cursor: pointer;
  align-self: center;
}
#content {
  display: inline !important;
}
#hindi {
  margin: 1% !important;
  display: inline;
  height: 1.6rem;
  font-family: "Lato", "sans-seri";
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 1.6rem;
}
#english {
  font-family: "Lato", "sans-seri";
  margin-left: 1% !important;
  display: inline;
  height: 1.7rem;
  font-style: normal;
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 1.7rem;
  text-align: center;
}
@media (max-width: 998px) {
  html {
    font-size: 55%;
  }
}
@media (max-width: 768px) {
  html {
    font-size: 45%;
  }
}

@media (max-width: 960px) {
  #logo {
    width: 100px;
    height: 33.84px;
    margin: 0;
  }
}
nav {
  display: flex;
  background-color: #000f36;
  padding: 8px 12px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 12;
  box-shadow: 0px 4px 8px #000f36b3;
  transition: top 0.3s ease-in;
}

.left-header {
  display: flex;
  flex-direction: row;
  flex: 1 1;
}

.mobile-navigation {
  max-height: 0;
  flex-direction: column;
  width: 100%;
  position: absolute;
  top: 100%;
  left: 0;
  padding-left: 16px;
  background-color: #000f36;
  z-index: 12;
  box-shadow: 0px 4px 8px #000f36b3;
  overflow-y: hidden;
  transition: max-height 0.2s ease-in;
}

nav .navLink {
  padding: 12px;
  display: flex;
  line-height: 1;
  display: block;
  padding: 12px;
  text-decoration: none;
  cursor: pointer;
}

/* Style the links inside the navigation menu */
.navLink span {
  color: #fff;
  font-family: Open Sans;
  font-size: 14px;
  line-height: 19px;
  text-decoration: none;
  cursor: pointer;
}

/* Change the color of links on hover */
.navLink:hover span {
  color: #acb3ff;
}
.navLink.active span {
  color: #acb3ff;
  font-weight: 600;
  font-size: 16px;
}

.logo-anchor {
  width: 140px;
}

.logo {
  margin-right: auto;
  margin-left: auto;
  display: block;
  width: auto;
  height: 32px;
}

.icon-container {
  border-radius: 4px;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.icons-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1 1;
  justify-content: flex-end;
}
.icons {
  height: 20px;
}
@media screen and (max-width: 960px) {
  .left-header {
    display: none;
  }
  .mobile-navigation.visible {
    max-height: 500px;
    display: flex;
  }

  /* Add hamburger menu */
  .nav-icon {
    margin-left: 8px;
    margin-right: 8px;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
  }
  /* Style the hamburger menu icon */
  .nav-icon span {
    display: block;
    width: 22px;
    height: 2px;
    margin-bottom: 4px;
    position: relative;
    background-color: #fff;
  }
  .nav-icon span:nth-child(3) {
    margin-bottom: 0;
  }
  .logo-anchor {
    /* flex: 2; */
  }
  .logo {
    width: 100px;
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 14px;
  margin-left:13%;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color:#424141 ;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  bottom: -1.5px;
  background-color:  #F8E71C;
  transition: .4s;
}



input:checked + .slider:before {
  -webkit-transform: translateX(15px);
  transform: translateX(15px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
@media  screen and (max-width:900px) {
  .switch{
    margin-left: 0%;
  }
  .slider:before {
    bottom: -2.5px;
  }
}
html {
  font-size: 62.5%;
}

#aboutSection {
  margin-top: 9rem !important;
  margin-bottom: 3%;
  padding-left: 10%;
  padding-right: 10%;
}
.about-img {
  height: 6rem;
  width: 7rem;
}
#inline {
  width: 100%;
  display: flex;
}
#socialIcon {
  display: flex;
  width: 24%;
  margin-left: 20px;
  justify-content: space-between;
}
#neend {
  font-family: "Comfortaa", cursive;
  font-style: normal;
  font-weight: bold;
  font-size: 2.4rem;
  line-height: 2.2rem;
  /* identical to box height, or 92% */

  color: #ffffff;
}
#playS {
  height: 4.2rem;
  width: 13rem;
}

#textfiler {
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-size: 1.5rem;
  letter-spacing: 1px;
  margin-top: 0.8rem;
  color: #ffffff;
  line-height: 2.3rem;
  word-spacing: 2px;
  font-weight: lighter;
}
#follow-up {
  display: inline;
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.6rem;
  /* or 114% */
  color: #ffffff;
}
#social {
  margin-right: 1%;
  margin-left: 3%;
}

#downloadText {
  font-family: "Lato";
  font-weight: bold;
  font-size: 14px;
  color: #ffffff;
}
#downloadTextsec {
  margin-top: 1.7rem;
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 1.6rem;
  color: #ffffff;
}
#downloadTextthird {
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.6rem;
  color: #ffffff;
  margin-top: 41.01px;
  margin-bottom: 13px;
}
#dots {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  /* identical to box height */
  font-size: 1.5rem;
  margin-top: 138%;

  color: #1d2041;

  -webkit-transform: rotate(90deg);

          transform: rotate(90deg);
}
#space {
  margin-right: 24px;
}
#col {
  margin-left: 8%;
  margin-top: 8rem;
}
@media screen and (max-width: 900px) {
  html {
    font-size: 70% !important;
  }
  #col {
    margin-left: 0;
    margin-top: 3rem;
    border: 1px solid #7f6ecc;
    border-radius: 3%;
    padding: 2rem;
  }

  #youtubr {
    margin-bottom: 2rem;
  }
  #plays {
    margin-bottom: 3rem;
  }
  #follow-up {
    margin-top: 6%;
  }
  #social {
    margin-left: 1rem !important;
  }
}
#textfiler {
  line-height: 2.3rem;
  font-size: 1.8rem;
}
@media screen and (max-width: 600px) {
  html {
    font-size: 55% !important;
  }

  #col {
    margin-left: 0;
    margin-top: 3rem;
    border: 1px solid #7f6ecc;
    border-radius: 0.8rem;
    padding: 2rem;
  }
  #youtubr {
    margin-bottom: 1rem;
  }
  #plays {
    margin-bottom: 3rem;
  }
  #follow-up {
    margin-top: 7% !important;
  }
  #social {
    margin-left: 1rem !important;
  }
  #textfiler {
    line-height: 2.3rem;
    font-size: 1.8rem;
  }
  #downloadText {
    margin-top: 49px;
    margin-bottom: 13px;
  }
  #downloadTextthird {
  }
  #follow-up {
    font-family: "Lato", sans-serif;
    font-size: 14px;
    font-weight: 700;
    color: #ffff;
    margin-bottom: 20px;
  }
  #inline {
    display: flex;
    flex-direction: column;
  }
  #socialIcon {
    display: flex;
    align-content: flex-start;
    margin-top: 20px;
    margin: -9px;
  }
}

/* #ig {
  background-image: url("../../images/lines.svg");
  background-position: top, bottom;
  background-repeat: no-repeat, repeat;
  overflow-x: hidden;
  z-index: -2;
} */

