html {
  font-size: 62.5%;
}

#aboutSection {
  margin-top: 9rem !important;
  margin-bottom: 3%;
  padding-left: 10%;
  padding-right: 10%;
}
.about-img {
  height: 6rem;
  width: 7rem;
}
#inline {
  width: 100%;
  display: flex;
}
#socialIcon {
  display: flex;
  width: 24%;
  margin-left: 20px;
  justify-content: space-between;
}
#neend {
  font-family: "Comfortaa", cursive;
  font-style: normal;
  font-weight: bold;
  font-size: 2.4rem;
  line-height: 2.2rem;
  /* identical to box height, or 92% */

  color: #ffffff;
}
#playS {
  height: 4.2rem;
  width: 13rem;
}

#textfiler {
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-size: 1.5rem;
  letter-spacing: 1px;
  margin-top: 0.8rem;
  color: #ffffff;
  line-height: 2.3rem;
  word-spacing: 2px;
  font-weight: lighter;
}
#follow-up {
  display: inline;
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.6rem;
  /* or 114% */
  color: #ffffff;
}
#social {
  margin-right: 1%;
  margin-left: 3%;
}

#downloadText {
  font-family: "Lato";
  font-weight: bold;
  font-size: 14px;
  color: #ffffff;
}
#downloadTextsec {
  margin-top: 1.7rem;
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 1.6rem;
  color: #ffffff;
}
#downloadTextthird {
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.6rem;
  color: #ffffff;
  margin-top: 41.01px;
  margin-bottom: 13px;
}
#dots {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  /* identical to box height */
  font-size: 1.5rem;
  margin-top: 138%;

  color: #1d2041;

  transform: rotate(90deg);
}
#space {
  margin-right: 24px;
}
#col {
  margin-left: 8%;
  margin-top: 8rem;
}
@media screen and (max-width: 900px) {
  html {
    font-size: 70% !important;
  }
  #col {
    margin-left: 0;
    margin-top: 3rem;
    border: 1px solid #7f6ecc;
    border-radius: 3%;
    padding: 2rem;
  }

  #youtubr {
    margin-bottom: 2rem;
  }
  #plays {
    margin-bottom: 3rem;
  }
  #follow-up {
    margin-top: 6%;
  }
  #social {
    margin-left: 1rem !important;
  }
}
#textfiler {
  line-height: 2.3rem;
  font-size: 1.8rem;
}
@media screen and (max-width: 600px) {
  html {
    font-size: 55% !important;
  }

  #col {
    margin-left: 0;
    margin-top: 3rem;
    border: 1px solid #7f6ecc;
    border-radius: 0.8rem;
    padding: 2rem;
  }
  #youtubr {
    margin-bottom: 1rem;
  }
  #plays {
    margin-bottom: 3rem;
  }
  #follow-up {
    margin-top: 7% !important;
  }
  #social {
    margin-left: 1rem !important;
  }
  #textfiler {
    line-height: 2.3rem;
    font-size: 1.8rem;
  }
  #downloadText {
    margin-top: 49px;
    margin-bottom: 13px;
  }
  #downloadTextthird {
  }
  #follow-up {
    font-family: "Lato", sans-serif;
    font-size: 14px;
    font-weight: 700;
    color: #ffff;
    margin-bottom: 20px;
  }
  #inline {
    display: flex;
    flex-direction: column;
  }
  #socialIcon {
    display: flex;
    align-content: flex-start;
    margin-top: 20px;
    margin: -9px;
  }
}
