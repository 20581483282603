.switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 14px;
  margin-left:13%;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color:#424141 ;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  bottom: -1.5px;
  background-color:  #F8E71C;
  ;
  -webkit-transition: .4s;
  transition: .4s;
}



input:checked + .slider:before {
  -webkit-transform: translateX(15px);
  -ms-transform: translateX(15px);
  transform: translateX(15px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
@media  screen and (max-width:900px) {
  .switch{
    margin-left: 0%;
  }
  .slider:before {
    bottom: -2.5px;
  }
}