html {
  font-size: 62.5%;
}
#logo {
  width: 140px;
  /* height: 60.92px; */
  margin: 8px;
  cursor: pointer;
  align-self: center;
}
#content {
  display: inline !important;
}
#hindi {
  margin: 1% !important;
  display: inline;
  height: 1.6rem;
  font-family: "Lato", "sans-seri";
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 1.6rem;
}
#english {
  font-family: "Lato", "sans-seri";
  margin-left: 1% !important;
  display: inline;
  height: 1.7rem;
  font-style: normal;
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 1.7rem;
  text-align: center;
}
@media (max-width: 998px) {
  html {
    font-size: 55%;
  }
}
@media (max-width: 768px) {
  html {
    font-size: 45%;
  }
}

@media (max-width: 960px) {
  #logo {
    width: 100px;
    height: 33.84px;
    margin: 0;
  }
}
nav {
  display: flex;
  background-color: #000f36;
  padding: 8px 12px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 12;
  box-shadow: 0px 4px 8px #000f36b3;
  transition: top 0.3s ease-in;
}

.left-header {
  display: flex;
  flex-direction: row;
  flex: 1;
}

.mobile-navigation {
  max-height: 0;
  flex-direction: column;
  width: 100%;
  position: absolute;
  top: 100%;
  left: 0;
  padding-left: 16px;
  background-color: #000f36;
  z-index: 12;
  box-shadow: 0px 4px 8px #000f36b3;
  overflow-y: hidden;
  transition: max-height 0.2s ease-in;
}

nav .navLink {
  padding: 12px;
  display: flex;
  line-height: 1;
  display: block;
  padding: 12px;
  text-decoration: none;
  cursor: pointer;
}

/* Style the links inside the navigation menu */
.navLink span {
  color: #fff;
  font-family: Open Sans;
  font-size: 14px;
  line-height: 19px;
  text-decoration: none;
  cursor: pointer;
}

/* Change the color of links on hover */
.navLink:hover span {
  color: #acb3ff;
}
.navLink.active span {
  color: #acb3ff;
  font-weight: 600;
  font-size: 16px;
}

.logo-anchor {
  width: 140px;
}

.logo {
  margin-right: auto;
  margin-left: auto;
  display: block;
  width: auto;
  height: 32px;
}

.icon-container {
  border-radius: 4px;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.icons-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  justify-content: flex-end;
}
.icons {
  height: 20px;
}
@media screen and (max-width: 960px) {
  .left-header {
    display: none;
  }
  .mobile-navigation.visible {
    max-height: 500px;
    display: flex;
  }

  /* Add hamburger menu */
  .nav-icon {
    margin-left: 8px;
    margin-right: 8px;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
  }
  /* Style the hamburger menu icon */
  .nav-icon span {
    display: block;
    width: 22px;
    height: 2px;
    margin-bottom: 4px;
    position: relative;
    background-color: #fff;
  }
  .nav-icon span:nth-child(3) {
    margin-bottom: 0;
  }
  .logo-anchor {
    /* flex: 2; */
  }
  .logo {
    width: 100px;
  }
}
